import { Button } from "react-bootstrap";
import "../Styles/BackButton.css";
import leftSideImg from "../Assets/leftSide.svg";
import { useNavigate } from "react-router-dom";

function BackButton() {

    const navigate= useNavigate();

    const handleItemClick=()=>{
        navigate('/')
    }

    return (
        <div className="back-btn-main" onClick={()=>handleItemClick()}>
            <img src={leftSideImg} alt="left-side-arrow" className="back-btn-img"/>
            <Button variant="link" className="back-btn">Back</Button>
        </div>
    )
}

export default BackButton;