import "../Styles/Service.css"

function Service() {

    return (
        <>
            <p className="service-head">Services</p>
            <p className="service-para">We offer a comprehensive range of mortgage services tailored to meet your specific needs. Whether you're a first-time homebuyer, looking to refinance, or interested in investment properties, our experienced team is here to guide you every step of the way.</p>
        </>
    )
}

export default Service;