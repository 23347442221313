import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import "../Styles/Reviews.css";
import sliderImg1 from "../Assets/sliderImg1.png";
import sliderImg2 from "../Assets/sliderImg2.png";
import sliderImg3 from "../Assets/sliderImg3.png";
import sliderImg4 from "../Assets/sliderImg4.png";
import sliderImg5 from "../Assets/sliderImg5.png";
import sliderImg6 from "../Assets/sliderImg6.png";
import sliderRating from "../Assets/sliderRating.svg";
import SliderBackground from "../Assets/SliderBackground.png";

function Reviews() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [selectedSlide, setSelectedSlide] = useState(0);
    const [prevIndex, setPrevIndex] = useState(5);
    const [nextIndex, setNextIndex] = useState(1);
    const [slidesToShow, setSlidesToShow] = useState(5);

    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1.current);
        setNav2(sliderRef2.current);
    }, [selectedSlide]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setSlidesToShow(1);
            } else if (window.innerWidth <= 993) {
                setSlidesToShow(3);
            } else {
                setSlidesToShow(5);
            }
        };

        // Initial check on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleBeforeChange = (oldIndex, newIndex) => {
        setSelectedSlide(newIndex);
        updatePrevNextIndices(newIndex);
    };

    const updatePrevNextIndices = currentIndex => {
        const totalSlides = nav1 && nav1.props.children.length;
        const prev = (currentIndex - 1 + totalSlides) % totalSlides;
        const next = (currentIndex + 1) % totalSlides;
        setPrevIndex(prev);
        setNextIndex(next);
    };

    const settings = {
        asNavFor: nav1,
        ref: slider => (sliderRef2.current = slider),
        slidesToShow: slidesToShow,
        swipeToSlide: true,
        focusOnSelect: true,
        className: "nav-slider",
        centerMode: true,
        lazyLoad: true,
        // fade: true,
        beforeChange: handleBeforeChange,
        // autoplay: true,
        // autoplaySpeed: 4000,
        // speed: 1000
    };

    return (
        <Container>
            <h4 className="slider-head">What Others Say About Us</h4>
            <div className="slider-main">
                <div>
                    <img src={SliderBackground} alt="slider-background-img" className="slider-back-img" />
                </div>
                <div className="slider-container">
                    <Slider {...settings}>
                        <div className="slider-content-img">
                            <div>
                                <div className="img-main">
                                    <img src={sliderImg1} alt="first-image" className={`${prevIndex === 4 && nextIndex === 0 || prevIndex === 0 && nextIndex === 2 || selectedSlide === 5 ? "set-img" : "slider-img"}`} />
                                </div>
                            </div>
                        </div>
                        <div className="slider-content-img">
                            <div>
                                <div className={`${selectedSlide !== 0 && "img-main"}`}>
                                    <img src={sliderImg2} alt="second-image" className={`${prevIndex === 5 && nextIndex === 1 || prevIndex === 1 && nextIndex === 3 || selectedSlide === 0 ? "set-img" : "slider-img"}`} />
                                </div>
                            </div>
                        </div>
                        <div className="slider-content-img">
                            <div className="img-main">
                                <img src={sliderImg3} alt="third-image" className={`${prevIndex === 0 && nextIndex === 2 || prevIndex === 2 && nextIndex === 4 || selectedSlide === 1 ? "set-img" : "slider-img"}`} />
                            </div>
                        </div>
                        <div className="slider-content-img">
                            <div className="img-main">
                                <img src={sliderImg4} alt="fourth-image" className={`${prevIndex === 1 && nextIndex === 3 || prevIndex === 3 && nextIndex === 5 || selectedSlide === 2 ? "set-img" : "slider-img"}`} />
                            </div>
                        </div>
                        <div className="slider-content-img">
                            <div className="img-main">
                                <img src={sliderImg5} alt="fifth-image" className={`${prevIndex === 2 && nextIndex === 4 || prevIndex === 4 && nextIndex === 0 || selectedSlide === 3 ? "set-img" : "slider-img"}`} />
                            </div>
                        </div>
                        <div className="slider-content-img">
                            <div className="img-main">
                                <img src={sliderImg6} alt="sixth-image" className={`${prevIndex === 3 && nextIndex === 5 || prevIndex === 5 && nextIndex === 1 || selectedSlide === 4 ? "set-img" : "slider-img"}`} />
                            </div>
                        </div>
                    </Slider>

                    <div className="second-slider-main">
                    <Slider
                        asNavFor={nav2}
                        ref={slider => (sliderRef1.current = slider)}
                        dots={true}
                        speed={1000}
                        className="content-slider"
                    >
                        <div className={selectedSlide === 0 ? "slider-slide zoomed" : "slider-slide"}>
                            <img src={sliderRating} alt="rating-img" />
                            <p className="slider-name">MN</p>
                            <p className="slider-role"></p>
                            <p className="slider-para">
                                Neal is the best Mortgage Agent to work with; he’s dedicated and committed to his work, always putting the clients needs first. Thanks Neal for the amazing service!! Will definitely recommend to my friends and family. 
                            </p>
                        </div>
                        <div className={selectedSlide === 1 ? "slider-slide zoomed" : "slider-slide"}>
                            <img src={sliderRating} alt="rating-img" />
                            <p className="slider-name">Ahnaf Al Wahed</p>
                            <p className="slider-role"></p>
                            <p className="slider-para">
                                Very genuine team. Neal worked with me patiently to find the right deal, will always be my guy going forward.
                            </p>
                        </div>
                        <div className={selectedSlide === 2 ? "slider-slide zoomed" : "slider-slide"}>
                            <img src={sliderRating} alt="rating-img" />
                            <p className="slider-name">Joshua Vetere</p>
                            <p className="slider-role"></p>
                            <p className="slider-para">
                                
                            </p>
                        </div>
                        <div className={selectedSlide === 3 ? "slider-slide zoomed" : "slider-slide"}>
                            <img src={sliderRating} alt="rating-img" />
                            <p className="slider-name">Pranjal Saloni</p>
                            <p className="slider-role"></p>
                            <p className="slider-para">
                                I had an amazing experience working with Neal from start to finish. As a first time homebuyer, Neal made understanding the mortgage process smooth and stress free for me. From the initial consultation to closing, Neal was patient, took the time to guide me through every step of the process. He was always responsive and kept me updated. I would highly recommend Neal to anyone seeking a trustworthy mortgage agent!
                            </p>
                        </div>
                        <div className={selectedSlide === 4 ? "slider-slide zoomed" : "slider-slide"}>
                            <img src={sliderRating} alt="rating-img" />
                            <p className="slider-name">Nirmit Joshi</p>
                            <p className="slider-role"></p>
                            <p className="slider-para">
                                Neal is one of the best in the biz!! If you need help to find the best mortgage for your needs, he is the one who could helps a lot in the process. Super easy to work with. Recommended!! 
                            </p>
                        </div>
                        <div className={selectedSlide === 5 ? "slider-slide zoomed" : "slider-slide"}>
                            <img src={sliderRating} alt="rating-img" />
                            <p className="slider-name">Cvm Ptl</p>
                            <p className="slider-role"></p>
                            <p className="slider-para">
                                Neal is an outstanding mortgage agent, dedicated and committed to putting clients' needs first. His amazing service has earned my highest recommendation to friends and family.
                            </p>
                        </div>
                    </Slider>
                    </div>
                </div>
                {/* <div className="line-main">
                    <div className="left-line"></div>
                    <div className="right-line"></div>
                </div> */}
            </div>
        </Container>
    );
}

export default Reviews;
