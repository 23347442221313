import { BrowserRouter, Routes, Route } from "react-router-dom";
import PreApproved from "../Pages/PreApproved";
import Home from "../Pages/Home";
import ContactNow from "../Pages/ContactNow";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsAndConditions from "../Pages/TermsAndConditions";


function PageRoute() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/approved" element={<PreApproved />} />
                <Route path="/contact-now" element={<ContactNow />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            </Routes>
        </BrowserRouter>
    )
}

export default PageRoute;