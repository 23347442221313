import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardImage1 from '../Assets/CardImage1.jpg'
import CardImage2 from '../Assets/CardImage2.png'
import CardImage3 from '../Assets/CardImage3.png'
import '../Styles/CardContent.css'
import Container from 'react-bootstrap/esm/Container';
import Service from "./Service"

function CardContent() {
    return (
        <>
            <Container>
                <Service />
                {/* <Row className='card-row'>
                    <Col lg={6} sm={6} md={6} xs={12}>
                        <Card.Img src={CardImage1} alt='no-card-imgage' className='CardImage' />
                    </Col>
                    <Col lg={6} sm={6} md={6} xs={12}>
                        <Card className='card'>
                            <Card.Body>
                                <Card.Title className='card-heading'>
                                    Financing Residential and Commercial Purchases
                                </Card.Title>
                                <Card.Text className='card-content'>
                                    “Secure the perfect loan for your new home or commercial property. With tailored financing options and expert guidance, we make the process of purchasing real estate seamless and stress-free. Whether it's a cozy family home or a bustling commercial space, we have the solutions to fit your needs.”
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row xs={1} md={2} className='card-row'>
                    <Col className='change-card-order' lg={6} sm={6} md={6} xs={12}>
                        <Card className='card'>
                            <Card.Body>
                                <Card.Title className='card-heading'>
                                    Private Lending
                                </Card.Title>
                                <Card.Text className='card-content'>
                                    “Get access to flexible and quick private lending solutions. Our private lending services provide a reliable alternative to traditional financing, ideal for those with unique circumstances or urgent funding needs. Benefit from personalized service and competitive rates to achieve your financial goals.”
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='change-content-order' lg={6} sm={6} md={6} xs={12}>
                        <Card.Img src={CardImage2} alt='no-card-imgage' className='CardImage' />
                    </Col>
                </Row>
                <Row xs={1} md={2} className='card-row'>
                    <Col lg={6} sm={6} md={6} xs={12}>
                        <Card.Img src={CardImage3} alt='no-card-imgage' className='CardImage' />
                    </Col>
                    <Col lg={6} sm={6} md={6} xs={12}>
                        <Card className='card'>
                            <Card.Body>
                                <Card.Title className='card-heading'>
                                    Debt Consolidation
                                </Card.Title>
                                <Card.Text className='card-content'>
                                    “Simplify your finances and reduce stress with our debt consolidation services. We help you combine multiple debts into one manageable monthly payment, often with lower interest rates. Regain control over your finances and work towards a debt-free future with our expert assistance.”
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}
                <Row>
                    <Col lg={6} sm={12} md={6} xs={12}>
                        <Card.Img src={CardImage1} alt='no-card-imgage' className='CardImage' />
                    </Col>
                    <Col lg={6} sm={12} md={6} xs={12}>
                        <Card className='card'>
                            <Card.Body>
                                <Card.Title className='card-heading'>
                                    Financing Residential and Commercial Purchases
                                </Card.Title>
                                <Card.Text className='card-content'>
                                    “Secure the perfect loan for your new home or commercial property. With tailored financing options and expert guidance, we make the process of purchasing real estate seamless and stress-free. Whether it's a cozy family home or a bustling commercial space, we have the solutions to fit your needs.”
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12} md={6} xs={12}>
                        <Card className='card'>
                            <Card.Body>
                                <Card.Title className='card-heading'>
                                    Private Lending
                                </Card.Title>
                                <Card.Text className='card-content'>
                                    “Get access to flexible and quick private lending solutions. Our private lending services provide a reliable alternative to traditional financing, ideal for those with unique circumstances or urgent funding needs. Benefit from personalized service and competitive rates to achieve your financial goals.”
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} sm={12} md={6} xs={12}>
                        <Card.Img src={CardImage2} alt='no-card-imgage' className='CardImage' />
                    </Col>
                </Row>
                <Row xs={1} md={2} className='card-row'>
                    <Col lg={6} sm={12} md={6} xs={12}>
                        <Card.Img src={CardImage3} alt='no-card-imgage' className='CardImage' />
                    </Col>
                    <Col lg={6} sm={12} md={6} xs={12}>
                        <Card className='card'>
                            <Card.Body>
                                <Card.Title className='card-heading'>
                                    Debt Consolidation
                                </Card.Title>
                                <Card.Text className='card-content'>
                                    “Simplify your finances and reduce stress with our debt consolidation services. We help you combine multiple debts into one manageable monthly payment, often with lower interest rates. Regain control over your finances and work towards a debt-free future with our expert assistance.”
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CardContent;