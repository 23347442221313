import logo from './logo.svg';
import Home from './Pages/Home';
import PageRoute from './Routes/PageRoute';

function App() {
  return (
    <div>
      <PageRoute />
    </div>
  );
}

export default App;
