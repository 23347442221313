import '../Styles/Blog.css';
import BlogImg1 from '../Assets/BlogImg1.svg';
import BlogImg2 from '../Assets/BlogImg2.svg';
import BlogImg3 from '../Assets/BlogImg3.svg';
import BlogImg4 from '../Assets/BlogImg4.svg';
import Container from 'react-bootstrap/esm/Container';
import { Col, Row } from 'react-bootstrap';

const handleBlog1Click = () => {
    window.open("https://www.northwoodmortgage.com/blog/7-things-you-need-to-know-about-getting-a-mortgage-in-2024/", "_blank");
};
const handleBlog2Click = () => {
    window.open("https://www.northwoodmortgage.com/blog/the-role-of-credit-scores-in-mortgage-approval-and-interest-rates/", "_blank");
};
const handleBlog3Click = () => {
    window.open("https://www.northwoodmortgage.com/blog/how-low-will-variable-mortgage-rates-be-in-2024/", "_blank");
};
const handleBlog4Click = () => {
    window.open("https://www.northwoodmortgage.com/blog/how-to-qualify-for-the-lowest-mortgage-rates-6-mortgage-eligibility-factors-to-keep-in-mind/", "_blank");
};

function Blog() {
    return (
        <>
            <p className="blog-head">Blogs</p>
            <div className='blog-main'>
            <Container>
                <Row className='blog-main-div'>
                    <Col lg={6} sm={12} md={6} xs={12} className='blog-column'>
                        <p className='blog-sub-head'>
                            First-time home buyer?
                        </p>
                        <p className='blog-content arrow-pointer' onClick={handleBlog1Click}>
                            7 Things You Need to Know About Getting a Mortgage in 2024.
                        </p>
                    </Col>
                    <Col lg={6} sm={12} md={6} xs={12} className='blog-column'>
                        <p className='blog-sub-head'>
                            Are you worried about your Credit Score?
                        </p>
                        <p className='blog-content arrow-pointer' onClick={handleBlog2Click}>
                            The Role of Credit Scores in Mortgage Approval and Interest Rates.
                        </p>
                    </Col>
                </Row>
                <Row className='blog-main-div'>
                    <Col lg={6} sm={12} md={6} xs={12} className='blog-column'>
                        <p className='blog-sub-head'>
                            2024 Mortgage Rates?
                        </p>
                        <p className='blog-content arrow-pointer' onClick={handleBlog3Click}>
                            How Low Will Variable Mortgage Rates Be In 2024.
                        </p>
                    </Col>
                    <Col lg={6} sm={12} md={6} xs={12} className='blog-column'>
                        <p className='blog-sub-head'>
                            Want to know your Mortgage Eligibility?
                        </p>
                        <p className='blog-content arrow-pointer' onClick={handleBlog4Click}>
                            How to Qualify for the Lowest Mortgage Rates: 6 Mortgage Eligibility Factors to Keep In Mind.
                        </p>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default Blog;