import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavbarLogo from '../Assets/logo.svg';
import '../Styles/NavbarMain.css';
import { useNavigate } from 'react-router-dom';

function NavbarMain() {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('');

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
        if (item === 'testimonials') {
            navigate("/", { state: "testimonials" });
            scrollToSection('reviews-section');
        } else if (item === 'contact-now' || item === 'approved') {
            navigate(`/${item}`);
        } else {
            navigate(`/${item}`);
        }
    };

    useEffect(() => {
        const path = window.location.pathname.substring(1);
        setActiveItem(path || '');
    }, []);

    return (
        <Container className='nav-main'>
            <Navbar key={'sm'} expand={'sm'} className="mb-3">
                {/* <Navbar.Brand> */}
                    <img src={NavbarLogo} alt="logo" className='nav-logo' onClick={() => navigate("/")} />
                {/* </Navbar.Brand> */}
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'sm'}`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${'sm'}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${'sm'}`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'sm'}`}>
                            <img src={NavbarLogo} alt="logo" className='nav-logo' onClick={() => navigate("/")} />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <button className={`nav-item ${activeItem === '' || activeItem === 'testimonials' ? 'active' : ''}`} onClick={() => handleItemClick("")}>Home</button>
                            <button className="nav-item" onClick={() => handleItemClick("testimonials")}>Testimonials</button>
                            <button className={`nav-item ${activeItem === 'contact-now' ? 'active' : ''}`} onClick={() => handleItemClick("contact-now")}>Contact Now</button>
                            <button className={`nav-item-btn ${activeItem === 'approved' ? 'active' : ''}`} onClick={() => handleItemClick("approved")}>Get Pre-Approved Now</button>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </Container>
    );
}

export default NavbarMain;
