import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/esm/Container";
import "../Styles/Footer.css";
import twitterImg from "../Assets/twitter.svg";
import linkedinImg from "../Assets/linkedin.svg";
import googleImg from "../Assets/google.svg";
import instagramImg from "../Assets/instagram.svg";
import footerImage from "../Assets/footerLogo.svg";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function Footer() {
    const openLink = (url) => {
        window.open(url, "_blank");
    };

    const [activeItem, setActiveItem] = useState('');
    const navigate = useNavigate();

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (item === 'testimonials') {
            navigate("/", { state: "testimonials" });
            scrollToSection('reviews-section');
        } else if (item === 'contact-now' || item === 'approved') {
            navigate(`/${item}`);
        }  else if (item === 'privacy-policy') {
                navigate(`/${item}`);
        }  else if (item === 'terms-and-conditions') {
                    navigate(`/${item}`);
        } else {
            navigate(`/${item}`);
        }
    };

    useEffect(() => {
        const path = window.location.pathname.substring(1);
        setActiveItem(path || '');
    }, []);

    return (
        <>
            <div className="footer">
                <Container className="footer-main">
                    {/* <div className="name-social">
                    <img src={footerImage} alt="logo-image" className="footer-logo arrow-pointer" onClick={() => navigate("/")} />
                    <div className="footer-content-social">
                        <div>
                            <address className="footer-address">
                                Neal Panchal - Mortgage Agent Level 2 <br />
                                Northwood Mortgage Inc.<br />
                                License #10349 <br />
                                <a href="mailto:npanchal@northwoodmortgage.com">npanchal@northwoodmortgage.com</a>
                            </address>
                        </div>
                        <div className="social-main">
                            <div className="social-main-div arrow-pointer" onClick={() => openLink('https://www.google.com')}>
                                <img src={googleImg} alt="google-img" className="google-img" />
                            </div>
                            <div className="social-main-div arrow-pointer" onClick={() => openLink('https://twitter.com')}>
                                <img src={twitterImg} alt="twitter-img" className="twitter-img" />
                            </div>
                            <div className="social-main-div arrow-pointer" onClick={() => openLink('https://www.instagram.com')}>
                                <img src={instagramImg} alt="instagram-img" className="instagram-img" />
                            </div>
                            <div className="social-main-div arrow-pointer" onClick={() => openLink('https://www.linkedin.com')}>
                                <img src={linkedinImg} alt="linkedin-img" className="linkedin-img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="topic-pages">
                    <p className="footer-menu">Company</p>
                    <p className="footer-child-menu" onClick={handleAboutClick}>About</p>
                    <p className="footer-child-menu">How it Works</p>
                    <p className="footer-child-menu">Term</p>
                    <p className="footer-child-menu">Privacy Policy</p>
                </div>
                <div className="topic-pages">
                    <p className="footer-menu">More</p>
                    <p className="footer-child-menu">Documentation</p>
                    <p className="footer-child-menu">License</p>
                </div> */}
                    <Row className="footer-main">
                        <Col lg={6} sm={12} md={6} xs={12}>
                            <img src={footerImage} alt="logo" className="footer-logo arrow-pointer" onClick={() => window.open("https://www.mortgageweb.ca/16827")}  />
                            <div className="footer-content-social">
                                <div>
                                    <address className="footer-address">
                                        Neal Panchal - Mortgage Agent Level 2 <br />
                                        Northwood Mortgage Inc.<br />
                                        License #10349 <br />
                                        <a href="mailto:npanchal@northwoodmortgage.com">npanchal@northwoodmortgage.com</a>
                                    </address>
                                </div>
                                <div className="social-main">
                                    <div className="social-main-div arrow-pointer" onClick={() => openLink('https://www.google.com/search?sa=X&sca_esv=25c9fa5c60f43d7f&sca_upv=1&sxsrf=ADLYWIKUV_tImgSbdu8p9SP50YvdChJYmw:1719337151871&q=Deals+By+Neal&si=ACC90nxUaQlEYyPWPer1nJgAB5evn4qtFnMAX98mvHiUas5jYWw3QlVeekqgdTmn-7Mb601vtK-hMirfHtXY55voYMStdiM4VeHkGFIJT9eV__dzBSUtZhhYgeyUKRDfzWvveLEZel7ef6oT6-eTZx_77Gsv3GjXLtRYsm03wpF2BgCIE1pLYW18KgztROKvLdGwV9eapI9lSvKznPmgiZfotvEv_9yCGub8Zp_hpQf1ai4RY9wvo9xUTI7MzfkwsyFwqkCiPY9n4aEK6hH3GsscGcbF_RqrIcG5nNGnXdW_OdgUG3Q-u3k%3D&ved=2ahUKEwies76BpveGAxVJLtAFHXQtCMYQ6RN6BAgbEAE&biw=1536&bih=711&dpr=1.25')}>
                                        <img src={googleImg} alt="google-img" className="google-img" />
                                    </div>
                                    <div className="social-main-div arrow-pointer" onClick={() => openLink('https://x.com/deals_byneal')}>
                                        <img src={twitterImg} alt="twitter-img" className="twitter-img" />
                                    </div>
                                    <div className="social-main-div arrow-pointer" onClick={() => openLink('https://www.instagram.com/deals.byneal')}>
                                        <img src={instagramImg} alt="instagram-img" className="instagram-img" />
                                    </div>
                                    <div className="social-main-div arrow-pointer" onClick={() => openLink('https://www.linkedin.com/in/nealpanchal')}>
                                        <img src={linkedinImg} alt="linkedin-img" className="linkedin-img" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} sm={12} md={6} xs={12}>
                            <Row className="footer-topics">
                                <Col lg={4} sm={4} md={4} xs={6}>
                                    <div className="topic-pages">
                                        <p className="footer-menu">Company</p>
                                        <p className={`footer-child-menu ${activeItem === '' || activeItem === 'testimonials' ? 'active' : ''}`} onClick={() => handleItemClick("")}>About</p>
                                        <p className={`footer-child-menu ${activeItem === 'approved' ? 'active' : ''}`} onClick={() => handleItemClick("approved")}>Application</p>
                                        {/* <p className={`footer-child-menu ${activeItem === 'terms-and-conditions' ? 'active' : ''}`} onClick={() => handleItemClick("terms-and-conditions")}>Terms and Conditions</p> */}
                                        <p className={`footer-child-menu ${activeItem === 'privacy-policy' ? 'active' : ''}`} onClick={() => handleItemClick("privacy-policy")}>Privacy Policy</p>
                                    </div>
                                </Col>
                                {/* <Col lg={4} sm={4} md={4} xs={6}>
                                    <div className="topic-pages">
                                        <p className="footer-menu">More</p>
                                        <p className="footer-child-menu">Documentation</p>
                                        <p className="footer-child-menu">License</p>
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <p className="rights-para">© 2024 Deals By Neal Inc. All rights reserved.</p>
        </>
    );
}

export default Footer;
