import React, { useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Footer from '../Components/Footer';
import NavbarMain from '../Components/NavbarMain';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import BackButton from '../Components/BackButton';
import '../Styles/PreApproved.css';
import emailjs from 'emailjs-com';
import { FormHelperText } from '@mui/material';
import { Col, Row } from 'react-bootstrap';

function PreApproved() {
    const [formData, setFormData] = useState({
        amountRequested: '',
        purposeOfLoan: '',
        firstName: '',
        lastName: '',
        address: '',
        phoneNumber: '',
        emailAddress: '',
        incomeType: '',
        totalAnnualIncome: '',
        howDidYouHear: '',
        coApplicantFirstName: '',
        coApplicantLastName: '',
        coApplicantIncomeType: '',
        coApplicantTotalAnnualIncome: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const val = type === 'checkbox' ? checked : value;

        const updatedFormData = {
            ...formData,
            [name]: val,
        };

        const updatedErrors = { ...formErrors };
        if (formErrors[name]) {
            if (name === 'emailAddress') {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailPattern.test(val)) {
                    delete updatedErrors[name];
                }
            } else {
                if (val !== '') {
                    delete updatedErrors[name];
                }
            }
        }

        setFormData(updatedFormData);
        setFormErrors(updatedErrors);
    };

    const validateForm = () => {
        let errors = {};
        let formValid = true;

        if (!formData.amountRequested.trim()) {
            errors.amountRequested = 'Amount requested is required';
            formValid = false;
        }

        if (!formData.purposeOfLoan) {
            errors.purposeOfLoan = 'Purpose of loan is required';
            formValid = false;
        }

        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required';
            formValid = false;
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required';
            formValid = false;
        }

        if (!formData.address.trim()) {
            errors.address = 'Address is required';
            formValid = false;
        }

        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone Number is required';
            formValid = false;
        }

        if (!formData.emailAddress.trim()) {
            errors.emailAddress = 'Email address is required';
            formValid = false;
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.emailAddress)) {
                errors.emailAddress = 'Invalid email address';
                formValid = false;
            }
        }

        if (!formData.incomeType) {
            errors.incomeType = 'Income type is required';
            formValid = false;
        }

        if (!formData.totalAnnualIncome.trim()) {
            errors.totalAnnualIncome = 'Total annual income is required';
            formValid = false;
        }

        if (!formData.howDidYouHear) {
            errors.howDidYouHear = 'Please select how you heard about us';
            formValid = false;
        }

        if (!formData.coApplicantFirstName) {
            errors.coApplicantFirstName = 'First name of co-applicant is required';
            formValid = false;
        }

        if (!formData.coApplicantLastName) {
            errors.coApplicantLastName = 'Last name of co-applicant is required';
            formValid = false;
        }

        if (!formData.coApplicantIncomeType) {
            errors.coApplicantIncomeType = 'Income type is required';
            formValid = false;
        }

        if (!formData.coApplicantTotalAnnualIncome) {
            errors.coApplicantTotalAnnualIncome = 'Total annual income is required';
            formValid = false;
        }

        setFormErrors(errors);
        return formValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            const serviceID = process.env.REACT_APP_SERVICE_ID;
            const templateID1 = process.env.REACT_APP_TEMPLATE_ID1;
            const userId = process.env.REACT_APP_USER_ID;
            emailjs
                .sendForm(serviceID, templateID1, formRef.current, userId)
                .then(
                    (result) => {
                        console.log(result.text);
                        setFormData({
                            amountRequested: '',
                            purposeOfLoan: '',
                            firstName: '',
                            lastName: '',
                            address: '',
                            phoneNumber: '',
                            emailAddress: '',
                            incomeType: '',
                            totalAnnualIncome: '',
                            howDidYouHear: '',
                            coApplicantFirstName: '',
                            coApplicantLastName: '',
                            coApplicantIncomeType: '',
                            coApplicantTotalAnnualIncome: '',
                        });
                        setFormErrors({});
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        } else {
            console.error('Form validation failed');
        }
    };

    const handleReset = () => {
        setFormData({
            amountRequested: '',
            purposeOfLoan: '',
            firstName: '',
            lastName: '',
            address: '',
            phoneNumber: '',
            emailAddress: '',
            incomeType: '',
            totalAnnualIncome: '',
            howDidYouHear: '',
            coApplicantFirstName: '',
            coApplicantLastName: '',
            coApplicantIncomeType: '',
            coApplicantTotalAnnualIncome: '',
        });
        setFormErrors({});
    };

    return (
        <div className="approve-div">
            <NavbarMain />
            <Container style={{ paddingTop: '50px' }}>
                <BackButton />
                {/* <div className="approve-main">
                    <div className="approve-left">
                        <p className="main-head">Get Pre-Approved Now</p>
                        <p className="approve-content">
                            Let’s get you pre-approved for a more informed buying process. Please
                            fill in your details here and our experienced agent will get back to you within 24 hours.
                        </p>
                    </div>
                    <div className="approve-form-main">
                        <form className="form-main" ref={formRef} onSubmit={handleSubmit}>
                            <div className="form-part">
                                <p className="form-main-title">Loan Information</p>
                                <FormControl fullWidth className="mb-3">
                                    <TextField
                                        label="Amount Requested"
                                        variant="standard"
                                        name="amountRequested"
                                        value={formData.amountRequested}
                                        onChange={handleChange}
                                        error={!!formErrors.amountRequested}
                                        helperText={formErrors.amountRequested}
                                    />
                                </FormControl>
                                <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.purposeOfLoan}>
                                    <InputLabel>Purpose of Loan</InputLabel>
                                    <Select
                                        value={formData.purposeOfLoan}
                                        onChange={handleChange}
                                        name="purposeOfLoan"
                                        label="Purpose of Loan"
                                    >
                                        <MenuItem value="">Please Select One</MenuItem>
                                        <MenuItem value="1">One</MenuItem>
                                        <MenuItem value="2">Two</MenuItem>
                                        <MenuItem value="3">Three</MenuItem>
                                    </Select>
                                    {formErrors.purposeOfLoan && (
                                        <FormHelperText className='select-error'>{formErrors.purposeOfLoan}</FormHelperText>
                                    )}
                                </FormControl>
                            </div>

                            <div className="form-part">
                                <p className="form-main-title">Applicant Personal Information</p>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="First Name"
                                                variant="standard"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                error={!!formErrors.firstName}
                                                helperText={formErrors.firstName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Last Name"
                                                variant="standard"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                error={!!formErrors.lastName}
                                                helperText={formErrors.lastName}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth className="mb-3">
                                    <TextField
                                        label="Address"
                                        multiline
                                        rows={4}
                                        variant="filled"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        error={!!formErrors.address}
                                        helperText={formErrors.address}
                                    />
                                </FormControl>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Home Phone"
                                                variant="standard"
                                                name="homePhone"
                                                value={formData.homePhone}
                                                onChange={handleChange}
                                                error={!!formErrors.homePhone}
                                                helperText={formErrors.homePhone}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Mobile Phone"
                                                variant="standard"
                                                name="mobilePhone"
                                                value={formData.mobilePhone}
                                                onChange={handleChange}
                                                error={!!formErrors.mobilePhone}
                                                helperText={formErrors.mobilePhone}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth className="mb-3">
                                    <TextField
                                        label="Email Address"
                                        variant="standard"
                                        type="email"
                                        name="emailAddress"
                                        value={formData.emailAddress}
                                        onChange={handleChange}
                                        error={!!formErrors.emailAddress}
                                        helperText={formErrors.emailAddress}
                                    />
                                </FormControl>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Fax"
                                                variant="standard"
                                                name="fax"
                                                value={formData.fax}
                                                onChange={handleChange}
                                                error={!!formErrors.fax}
                                                helperText={formErrors.fax}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.incomeType}>
                                            <InputLabel>Type of Income</InputLabel>
                                            <Select
                                                value={formData.incomeType}
                                                onChange={handleChange}
                                                name="incomeType"
                                                label="Type of Income"
                                            >
                                                <MenuItem value="">Please Select One</MenuItem>
                                                <MenuItem value="1">One</MenuItem>
                                                <MenuItem value="2">Two</MenuItem>
                                                <MenuItem value="3">Three</MenuItem>
                                            </Select>
                                            {formErrors.incomeType && (
                                                <FormHelperText className='select-error'>{formErrors.incomeType}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Total Annual Income"
                                                variant="standard"
                                                name="totalAnnualIncome"
                                                value={formData.totalAnnualIncome}
                                                onChange={handleChange}
                                                error={!!formErrors.totalAnnualIncome}
                                                helperText={formErrors.totalAnnualIncome}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.howDidYouHear}>
                                            <InputLabel>How did you hear about us?</InputLabel>
                                            <Select
                                                value={formData.howDidYouHear}
                                                onChange={handleChange}
                                                name="howDidYouHear"
                                                label="How did you hear about us?"
                                            >
                                                <MenuItem value="">Please Select One</MenuItem>
                                                <MenuItem value="1">One</MenuItem>
                                                <MenuItem value="2">Two</MenuItem>
                                                <MenuItem value="3">Three</MenuItem>
                                            </Select>
                                            {formErrors.howDidYouHear && (
                                                <FormHelperText className='select-error'>{formErrors.howDidYouHear}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="form-part">
                                <p className="form-main-title">Co-Applicant Personal Information</p>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="First Name"
                                                variant="standard"
                                                name="coApplicantFirstName"
                                                value={formData.coApplicantFirstName}
                                                onChange={handleChange}
                                                error={!!formErrors.coApplicantFirstName}
                                                helperText={formErrors.coApplicantFirstName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Last Name"
                                                variant="standard"
                                                name="coApplicantLastName"
                                                value={formData.coApplicantLastName}
                                                onChange={handleChange}
                                                error={!!formErrors.coApplicantLastName}
                                                helperText={formErrors.coApplicantLastName}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.coApplicantIncomeType}>
                                            <InputLabel>Type of Income</InputLabel>
                                            <Select
                                                value={formData.coApplicantIncomeType}
                                                onChange={handleChange}
                                                name="coApplicantIncomeType"
                                                label="Type of Income"
                                            >
                                                <MenuItem value="">Please Select One</MenuItem>
                                                <MenuItem value="1">One</MenuItem>
                                                <MenuItem value="2">Two</MenuItem>
                                                <MenuItem value="3">Three</MenuItem>
                                            </Select>
                                            {formErrors.coApplicantIncomeType && (
                                                <FormHelperText className='select-error'>{formErrors.coApplicantIncomeType}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Total Annual Income"
                                                variant="standard"
                                                name="coApplicantTotalAnnualIncome"
                                                value={formData.coApplicantTotalAnnualIncome}
                                                onChange={handleChange}
                                                error={!!formErrors.coApplicantTotalAnnualIncome}
                                                helperText={formErrors.coApplicantTotalAnnualIncome}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth className="mb-3" error={!!formErrors.subscribeToNewsletter}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.subscribeToNewsletter}
                                                onChange={handleChange}
                                                name="subscribeToNewsletter"
                                                color="primary"
                                                style={{
                                                    color: formErrors.subscribeToNewsletter ? '#d32f2f' : 'inherit',
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography color={formErrors.subscribeToNewsletter ? 'error' : 'initial'}>
                                                Subscribe to newsletter?
                                            </Typography>
                                        }
                                    />
                                    {formErrors.subscribeToNewsletter && (
                                        <FormHelperText>{formErrors.subscribeToNewsletter}</FormHelperText>
                                    )}
                                </FormControl>
                            </div>

                            <div className="approve-btn-main">
                                <Button variant="outlined" className="reset-btn" onClick={handleReset}>
                                    Reset
                                </Button>
                                <Button variant="contained" color="primary" className="approve-btn" type="submit">
                                    Submit Form
                                </Button>
                            </div>
                        </form>
                    </div>
                </div> */}
                <Row className='preapprove-form-main'>
                    <Col lg={6} sm={6} md={6} xs={12}>
                        <div className="approve-left">
                            <p className="main-head">Get Pre-Approved Now</p>
                            <p className="approve-content">
                                Let’s get you pre-approved for a more informed buying process. Please
                                fill in your details here and our experienced agent will get back to you within 24 hours.
                            </p>
                        </div>
                        <form className="form-main" ref={formRef} onSubmit={handleSubmit}>
                            <div className="form-part">
                                <p className="form-main-title">Loan Information</p>
                                <FormControl fullWidth className="mb-3">
                                    <TextField
                                        label="Amount Requested"
                                        variant="standard"
                                        name="amountRequested"
                                        value={formData.amountRequested}
                                        onChange={handleChange}
                                        error={!!formErrors.amountRequested}
                                        helperText={formErrors.amountRequested}
                                    />
                                </FormControl>
                                <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.purposeOfLoan}>
                                    <InputLabel>Purpose of Loan</InputLabel>
                                    <Select
                                        value={formData.purposeOfLoan}
                                        onChange={handleChange}
                                        name="purposeOfLoan"
                                        label="Purpose of Loan"
                                    >
                                        <MenuItem value="">Please Select One</MenuItem>
                                        <MenuItem value="Purchase">Purchase</MenuItem>
                                        <MenuItem value="Debt Consolidation">Debt Consolidation</MenuItem>
                                        <MenuItem value="Transfer Mortgage">Transfer Mortgage</MenuItem>
                                        <MenuItem value="Renovations">Renovations</MenuItem>
                                        <MenuItem value="Reverse Mortgage">Reverse Mortgage</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                    {formErrors.purposeOfLoan && (
                                        <FormHelperText className='select-error'>{formErrors.purposeOfLoan}</FormHelperText>
                                    )}
                                </FormControl>
                            </div>

                            <div className="form-part">
                                <p className="form-main-title">Applicant Personal Information</p>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="First Name"
                                                variant="standard"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                error={!!formErrors.firstName}
                                                helperText={formErrors.firstName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Last Name"
                                                variant="standard"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                error={!!formErrors.lastName}
                                                helperText={formErrors.lastName}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth className="mb-3">
                                    <TextField
                                        label="Address"
                                        multiline
                                        rows={4}
                                        variant="filled"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        error={!!formErrors.address}
                                        helperText={formErrors.address}
                                    />
                                </FormControl>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Phone Number"
                                                variant="standard"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                error={!!formErrors.phoneNumber}
                                                helperText={formErrors.phoneNumber}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                        <TextField
                                            label="Email Address"
                                            variant="standard"
                                            type="email"
                                            name="emailAddress"
                                            value={formData.emailAddress}
                                            onChange={handleChange}
                                            error={!!formErrors.emailAddress}
                                            helperText={formErrors.emailAddress}
                                        />
                                        </FormControl>
                                    </Grid>
                                    
                                    {/* <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Mobile Phone"
                                                variant="standard"
                                                name="mobilePhone"
                                                value={formData.mobilePhone}
                                                onChange={handleChange}
                                                error={!!formErrors.mobilePhone}
                                                helperText={formErrors.mobilePhone}
                                            />
                                        </FormControl>
                                    </Grid> */}
                                </Grid>

                                {/* <FormControl fullWidth className="mb-3">
                                    <TextField
                                        label="Email Address"
                                        variant="standard"
                                        type="email"
                                        name="emailAddress"
                                        value={formData.emailAddress}
                                        onChange={handleChange}
                                        error={!!formErrors.emailAddress}
                                        helperText={formErrors.emailAddress}
                                    />
                                </FormControl> */}

                                <Grid container spacing={2}>
                                    {/* <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Fax"
                                                variant="standard"
                                                name="fax"
                                                value={formData.fax}
                                                onChange={handleChange}
                                                error={!!formErrors.fax}
                                                helperText={formErrors.fax}
                                            />
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.incomeType}>
                                            <InputLabel>Type of Income</InputLabel>
                                            <Select
                                                value={formData.incomeType}
                                                onChange={handleChange}
                                                name="incomeType"
                                                label="Type of Income"
                                            >
                                                <MenuItem value="">Please Select One</MenuItem>
                                                <MenuItem value="Salaried">Salaried</MenuItem>
                                                <MenuItem value="Self Employed">Self Employed</MenuItem>
                                                <MenuItem value="Commision">Commision</MenuItem>
                                                <MenuItem value="Pension">Pension</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                            </Select>
                                            {formErrors.incomeType && (
                                                <FormHelperText className='select-error'>{formErrors.incomeType}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Total Annual Income"
                                                variant="standard"
                                                name="totalAnnualIncome"
                                                value={formData.totalAnnualIncome}
                                                onChange={handleChange}
                                                error={!!formErrors.totalAnnualIncome}
                                                helperText={formErrors.totalAnnualIncome}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    {/* <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Total Annual Income"
                                                variant="standard"
                                                name="totalAnnualIncome"
                                                value={formData.totalAnnualIncome}
                                                onChange={handleChange}
                                                error={!!formErrors.totalAnnualIncome}
                                                helperText={formErrors.totalAnnualIncome}
                                            />
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.howDidYouHear}>
                                            <InputLabel>How did you hear about us?</InputLabel>
                                            <Select
                                                value={formData.howDidYouHear}
                                                onChange={handleChange}
                                                name="howDidYouHear"
                                                label="How did you hear about us?"
                                            >
                                                <MenuItem value="">Please Select One</MenuItem>
                                                <MenuItem value="Instagram">Instagram</MenuItem>
                                                <MenuItem value="Google">Google</MenuItem>
                                                <MenuItem value="Friends/family">Friends/family</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                            </Select>
                                            {formErrors.howDidYouHear && (
                                                <FormHelperText className='select-error'>{formErrors.howDidYouHear}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="form-part">
                                <p className="form-main-title">Co-Applicant Personal Information</p>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="First Name"
                                                variant="standard"
                                                name="coApplicantFirstName"
                                                value={formData.coApplicantFirstName}
                                                onChange={handleChange}
                                                error={!!formErrors.coApplicantFirstName}
                                                helperText={formErrors.coApplicantFirstName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Last Name"
                                                variant="standard"
                                                name="coApplicantLastName"
                                                value={formData.coApplicantLastName}
                                                onChange={handleChange}
                                                error={!!formErrors.coApplicantLastName}
                                                helperText={formErrors.coApplicantLastName}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3" variant="filled" error={!!formErrors.coApplicantIncomeType}>
                                            <InputLabel>Type of Income</InputLabel>
                                            <Select
                                                value={formData.coApplicantIncomeType}
                                                onChange={handleChange}
                                                name="coApplicantIncomeType"
                                                label="Type of Income"
                                            >
                                                <MenuItem value="">Please Select One</MenuItem>
                                                <MenuItem value="Salaried">Salaried</MenuItem>
                                                <MenuItem value="Self Employed">Self Employed</MenuItem>
                                                <MenuItem value="Commision">Commision</MenuItem>
                                                <MenuItem value="Pension">Pension</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                            </Select>
                                            {formErrors.coApplicantIncomeType && (
                                                <FormHelperText className='select-error'>{formErrors.coApplicantIncomeType}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth className="mb-3">
                                            <TextField
                                                label="Total Annual Income"
                                                variant="standard"
                                                name="coApplicantTotalAnnualIncome"
                                                value={formData.coApplicantTotalAnnualIncome}
                                                onChange={handleChange}
                                                error={!!formErrors.coApplicantTotalAnnualIncome}
                                                helperText={formErrors.coApplicantTotalAnnualIncome}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* <FormControl fullWidth className="mb-3" error={!!formErrors.subscribeToNewsletter}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.subscribeToNewsletter}
                                                onChange={handleChange}
                                                name="subscribeToNewsletter"
                                                color="primary"
                                                style={{
                                                    color: formErrors.subscribeToNewsletter ? '#d32f2f' : 'inherit',
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography color={formErrors.subscribeToNewsletter ? 'error' : 'initial'}>
                                                Subscribe to newsletter?
                                            </Typography>
                                        }
                                    />
                                    {formErrors.subscribeToNewsletter && (
                                        <FormHelperText>{formErrors.subscribeToNewsletter}</FormHelperText>
                                    )}
                                </FormControl> */}
                            </div>

                            <div className="approve-btn-main">
                                <Button 
                                variant="outlined" 
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    border: '1px solid black',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: 'goldenrod',
                                        border: '1px solid goldenrod',
                                    },
                                }}
                                onClick={handleReset}>
                                    Reset
                                </Button>
                                <Button 
                                variant="contained" 
                                sx={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'black',
                                        color: 'goldenrod',
                                    },
                                }}
                                type="submit">
                                    Submit Form
                                </Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default PreApproved;
