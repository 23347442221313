import React, { useState, useEffect, useRef } from 'react';
import NavbarMain from '../Components/NavbarMain';
import Container from 'react-bootstrap/Container';
import Cardcontent from "../Components/CardContent";
import Footer from "../Components/Footer";
import "../Styles/Home.css";
import Reviews from '../Components/Reviews';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeImage from "../Assets/Home.png";
import Blogs from "../Components/Blog";
import { Col, Row } from 'react-bootstrap';

function Home() {
    const [activeItem, setActiveItem] = useState('');
    const navigate = useNavigate();
    const { state } = useLocation("");
    const reviewsSectionRef = useRef(null);

    const handleItemClick = (item) => {
        setActiveItem(item);
        if (item === 'contact-now') {
            navigate(`/${item}`);
        }
    };

    useEffect(() => {
        console.log("state", state);
        if (state == 'testimonials') {
            if (reviewsSectionRef.current) {
                reviewsSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    });

    return (
        <>
            <div className="home-main">
                <NavbarMain />
                <Container>
                    {/* <div className='home-content-main'>
                        <div className='home-content'>
                            <div>
                                <h1 className='heading'>Your Trusted Partner in Home Financing</h1>
                                <p className='home-para'>
                                    Navigating the complexities of mortgage options can be daunting, but it doesn't have to be. With years of industry experience and a commitment to personalized service, we are dedicated to helping you find the perfect mortgage solution tailored to your unique needs.
                                </p>
                            </div>
                            <div className='home-connect-btn'>
                                <button className={`connect-btn ${activeItem === 'contact-now' ? 'active' : ''}`} onClick={() => handleItemClick("contact-now")}>
                                    Connect Now
                                </button>
                            </div>
                        </div>
                        <div className='home-img'>
                            <img src={HomeImage} alt="home image" />
                        </div>
                    </div> */}
                    <Row className='home-main-content'>
                        <Col lg={6} sm={12} md={12} xs={12}>
                            {/* <div className='home-content'> */}
                            <div>
                                <h1 className='heading'>Your Trusted Partner in Home Financing</h1>
                                <p className='home-para'>
                                    Navigating the complexities of mortgage options can be daunting, but it doesn't have to be. With years of industry experience and a commitment to personalized service, we are dedicated to helping you find the perfect mortgage solution tailored to your unique needs.
                                </p>
                                {/* </div>
                                <div className='home-connect-btn'> */}
                                <button className={`connect-btn ${activeItem === 'contact-now' ? 'active' : ''}`} onClick={() => handleItemClick("contact-now")}>
                                    Connect Now
                                </button>
                            </div>
                            {/* </div> */}
                        </Col>
                        <Col lg={4} sm={12} md={12} xs={12}>
                            <img src={HomeImage} alt="home image" style={{ width: "100%"}} />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Cardcontent />
            <Blogs />
            <Container ref={reviewsSectionRef} id="reviews-section">
                <Reviews />
            </Container>
            <Footer />
        </>
    );
}

export default Home;
