import React from 'react';
import Container from '@mui/material/Container';
import Footer from '../Components/Footer';
import NavbarMain from '../Components/NavbarMain';
import '../Styles/TermsAndConditions.css';

function TermsAndConditions() {
    
    return (
        <div className="approve-div">
            <NavbarMain />
            <Container style={{ paddingTop: '50px' }}>

                        <div className="approve-left">
                            <p className="main-head">Terms And Conditions</p>
                            <p className="approve-content">
                                Content of Terms And Conditions.
                            </p>
                        </div>
                        
                    
            </Container>
            <Footer />
        </div>
    );
}

export default TermsAndConditions;
