import React, { useState } from 'react';
import Container from '@mui/material/Container';
import NavbarMain from '../Components/NavbarMain';
import BackButton from '../Components/BackButton';
import Footer from '../Components/Footer';
import { Grid, TextField, Button, Typography } from '@mui/material';
import emailjs from 'emailjs-com';
import '../Styles/ContactNow.css';

function ContactNow() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormErrors({
            ...formErrors,
            [name]: ''
        });

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        let valid = true;
        const errors = {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        };

        if (!formData.firstName.trim()) {
            errors.firstName = 'First Name is required';
            valid = false;
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last Name is required';
            valid = false;
        }

        if (!formData.email.trim()) {
            errors.email = 'Email Address is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email Address is invalid';
            valid = false;
        }

        if (!formData.message.trim()) {
            errors.message = 'Message is required';
            valid = false;
        }

        setFormErrors(errors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            const serviceID = process.env.REACT_APP_SERVICE_ID;
            const templateID2 = process.env.REACT_APP_TEMPLATE_ID2;
            const userId = process.env.REACT_APP_USER_ID;

            emailjs.sendForm(serviceID, templateID2, e.target, userId)
                .then((result) => {
                    console.log(result.text);
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        message: ''
                    });
                    setFormErrors({
                        firstName: '',
                        lastName: '',
                        email: '',
                        message: ''
                    });
                }, (error) => {
                    console.log(error.text);
                });
        }
    };

    return (
        <div className="contact-div">
            <NavbarMain />
            <Container style={{ paddingTop: '50px' }}>
                <BackButton />
                <div className="contact">
                        <div className="approve-left">
                            <p className="main-head">We're Here to Help</p>
                            <p className="approve-content">
                            Got questions or need assistance? Our team is ready to provide the support you need. Reach out to us anytime, and let’s work together to make your mortgage journey as smooth and straightforward as possible. Your satisfaction is our priority, and we’re committed to being with you every step of the way.
                            </p>
                        </div>
                    {/* <div className="contact-main">
                        <Typography variant="h4" className="main-title">
                            We're Here to Help
                        </Typography>
                        <Typography variant="body1" className="form-para">
                            Got questions or need assistance? Our team is ready to provide the support you need. Reach out to us anytime, and let’s work together to make your mortgage journey as smooth and straightforward as possible. Your satisfaction is our priority, and we’re committed to being with you every step of the way.
                        </Typography>
                    </div> */}
                    <form className="form-main" noValidate onSubmit={handleSubmit}>
                        <div className="form-box">
                            <Typography variant="h6" className="form-head">Personal Information</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        error={!!formErrors.firstName}
                                        helperText={formErrors.firstName}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        error={!!formErrors.lastName}
                                        helperText={formErrors.lastName}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Email Address"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={!!formErrors.email}
                                        helperText={formErrors.email}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        error={!!formErrors.message}
                                        helperText={formErrors.message}
                                        variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    
                                <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'black',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'black',
                                                color: 'goldenrod',
                                            },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </Container>
            <Footer />
        </div>
    );
}

export default ContactNow;
